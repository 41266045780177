import React from 'react';

import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
// import storeIcon from '~/assets/images/store.svg';

// import { setActiveTab } from '~/store/modules/shareable_cart/actions';

// import SaleSuccess from '~/assets/images/finish-sale-success.svg';
// import SalePaid from '~/assets/images/finish-sale-paid.svg';
import SaleError from '~/assets/images/finish-sale-error.svg';
import SaleExpirated from '~/assets/images/finish-sale-link-expired.svg';
import SaleCancelled from '~/assets/images/finish-sale-cancelled.svg';

import BoxIconSVG from '~/assets/images/icone-caixa.svg';
import TruckIconSVG from '~/assets/images/icone-caminhao.svg';
import ClockIconSVG from '~/assets/images/icone-relogio.svg';
import { formatPrice, formatWhatsappPhoneNumber } from '~/util/format';
// import { formatPrice } from '~/util/format';

export default function FinishedOrderInfo() {
  // const dispatch = useDispatch();

  const finishedSuccessfully = useSelector(
    ({ shareable_cart }) => shareable_cart.finishedSuccessfully
  );

  const cart = useSelector(({ shareable_cart }) => shareable_cart.cart);

  const getClientMessage = () => {
    if (cart.status_text === 'Aguardando estoquista')
      return 'Pedido sendo preparado.';

    if (
      cart?.status === 1 ||
      cart.status_text === 'Pagamento rejeitado pelo anti-fraude'
    )
      return 'Pedido em processamento.';

    if (cart.status_text === 'Encaminhado por estoquista') {
      if (!cart.delivery)
        return 'Pagamento confirmado, já pode retirar seu pedido em loja.';

      if (cart.delivery?.status === 'SEARCHING')
        return 'Pedido aguardando entregador.';

      if (cart.delivery.status_text === 'A caminho da loja')
        return 'Entregador a caminho da loja.';

      if (['ARRIVED_AT_STORE', 'ON_DELIVERY'].includes(cart.delivery?.status))
        return 'Entregador a caminho.';

      if (cart.delivery?.status === 'ARRIVED_AT_DESTINATION')
        return 'Entregador chegou ao destino.';

      if (cart.delivery?.status === 'CANCELED') return 'Entregador cancelou.';
    }

    if (cart.is_suitcase_delivery && cart.payment_process?.status === 'Paid')
      return 'Pagamento confirmado.';

    if (cart.status_text === 'Aguardando aceite')
      return 'Pedido em processamento.';

    return null;
  };

  const getClientIcon = () => {
    if (cart.status_text === 'Aguardando estoquista') return ClockIconSVG;
    if (cart.status_text === 'Encaminhado por estoquista') {
      if (
        !cart.delivery ||
        ['SEARCHING', 'ARRIVED_AT_DESTINATION'].includes(cart.delivery?.status)
      ) {
        return BoxIconSVG;
      }
      return TruckIconSVG;
    }
    if (
      cart?.status === 1 ||
      cart.status_text === 'Pagamento rejeitado pelo anti-fraude'
    )
      return ClockIconSVG;
    return BoxIconSVG;
  };

  const capitalize = text =>
    text
      .split(' ')
      .map(word => word[0].toUpperCase() + word.substring(1).toLowerCase())
      .join(' ');

  const formatPhone = phone => {
    if (!/^\d+$/.test(phone)) return phone;
    const ddd = phone.slice(0, 2);
    let first;
    let last;
    if (phone[2] === '9' && phone.length === 11) {
      first = phone.slice(2, 7);
      last = phone.slice(7, 11);
    } else {
      first = phone.slice(2, 6);
      last = phone.slice(6, 10);
    }
    return `(${ddd}) ${first}-${last}`;
  };

  return (
    <Row className="w-100 mx-0 px-3 pb-2 mb-md-4">
      <Container>
        <Card className="card new-layout mb-4 w-100">
          <CardBody className="card-body new-layout px-0 py-0 d-block d-lg-flex flex-lg-row-reverse">
            <Col lg={4} className="px-0 mt-0 col-lg-4">
              <Card className="card new-layout mb-4 w-100 h-100 background-order-details">
                <CardBody className="card-body new-layout px-0 px-lg-4 py-4">
                  <Row className="w-100 mx-0 px-3 pb-2 mb-md-4 my-3">
                    <div className="font-size-16 font-weight-400 text-black pb-4 d-flex justify-content-between w-100">
                      <span>Resumo do pedido</span>{' '}
                      <span className="font-weight-bold">
                        #{cart?.order_number}
                      </span>
                    </div>
                    <Card className="card new-layout mb-4 w-100">
                      <CardBody className="card-body new-layout px-3 py-3">
                        <Row className="mx-0 justify-content-between align-items-center">
                          <span className="font-size-14 font-weight-400 text-gray d-block">
                            Valor dos Produtos
                          </span>
                          <span className="font-size-14 font-weight-400 text-black">
                            {formatPrice(Number(cart?.amount))}
                          </span>
                        </Row>
                        {cart?.is_for_delivery && (
                          <Row className="mx-0 justify-content-between align-items-center mt-2">
                            <span className="font-size-14 font-weight-400 text-gray d-block">
                              Valor do Frete
                            </span>
                            <span className="font-size-14 font-weight-400 text-black">
                              {formatPrice(cart?.freight_user || 0)}
                            </span>
                          </Row>
                        )}
                        {cart?.giftback?.amount && cart.giftback.amount > 0 && (
                          <Row className="mx-0 mt-2">
                            <span className="font-size-14 font-weight-400 text-gray d-block">
                              Desconto Giftback
                            </span>
                            <span className="font-size-14 font-weight-400 text-red ml-auto">
                              -{formatPrice(cart?.giftback?.amount || 0)}
                            </span>
                          </Row>
                        )}
                        <hr />
                        <Row className="mx-0 justify-content-between align-items-center">
                          <span className="font-size-14 font-weight-400 text-gray d-block">
                            Valor Total
                          </span>
                          <span className="font-size-14 font-weight-400 text-black">
                            {formatPrice(
                              Number(cart?.amount) +
                                Number(cart?.freight_user || 0) -
                                (cart?.giftback?.amount &&
                                cart.giftback.amount > 0
                                  ? Number(cart.giftback.amount)
                                  : 0)
                            )}
                          </span>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="card new-layout mb-4 w-100">
                      <CardBody className="card-body new-layout px-3 py-3">
                        <span className="font-size-12 font-weight-400 text-gray d-block">
                          Loja
                        </span>
                        <span className="font-size-14 font-weight-400 text-black">
                          {cart?.store?.fantasy_name}
                        </span>
                        <hr />
                        <span className="font-size-12 font-weight-400 text-gray d-block">
                          Vendedor
                        </span>
                        <span className="font-size-14 font-weight-400 text-black">
                          {cart?.handled_by?.name || cart?.created_by?.name} -{' '}
                          <a
                            className="font-size-14 font-weight-400 text-black"
                            href={`https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                              cart?.handled_by?.telephone ||
                                cart?.created_by?.telephone
                            )}`}
                          >
                            {cart?.handled_by?.telephone ||
                              cart?.created_by?.telephone}
                          </a>
                        </span>
                        {([
                          'ARRIVED_AT_STORE',
                          'ON_DELIVERY',
                          'ARRIVED_AT_DESTINATION',
                        ].includes(cart?.delivery?.status) ||
                          cart?.delivery?.status_text ===
                            'A caminho da loja') && (
                          <>
                            <hr />
                            <span className="font-size-12 font-weight-400 text-gray d-block">
                              Entregador
                            </span>
                            <span className="font-size-14 font-weight-400 text-black">
                              {capitalize(cart?.delivery?.worker_name)}
                              {cart?.delivery?.worker_phone && (
                                <>
                                  <br />
                                  <a
                                    className="font-size-14 font-weight-400 text-black"
                                    href={`https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                                      cart?.delivery?.worker_phone
                                    )}`}
                                  >
                                    {formatPhone(cart?.delivery?.worker_phone)}
                                  </a>
                                </>
                              )}
                            </span>
                            <br />
                            {cart?.delivery?.track_data && (
                              <>
                                <a
                                  href={cart?.delivery?.track_data}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <span className="font-size-14 font-weight-400">
                                    Link de rastreio
                                  </span>
                                </a>
                                <hr />
                                <a
                                  href="https://api.whatsapp.com/send?phone=5511935354434"
                                  target="_blank"
                                  className="text-decoration-none text-reset"
                                  rel="noopener noreferrer"
                                  id="receiptLink"
                                >
                                  <span className="font-weight-bold">
                                    Problemas com a entrega?{' '}
                                  </span>
                                  Entre em contato com nosso suporte, clique
                                  aqui!
                                </a>
                              </>
                            )}
                          </>
                        )}
                      </CardBody>
                    </Card>
                    {cart?.is_for_delivery ? (
                      <>
                        {cart?.client && (
                          <Card className="card new-layout mb-4 w-100">
                            <CardBody className="card-body new-layout px-3 py-3">
                              <span className="font-size-12 font-weight-400 text-gray d-block">
                                Cliente
                              </span>
                              <span className="font-size-14 font-weight-400 text-black">
                                {cart?.client?.name} <br />
                                <a
                                  className="font-size-14 font-weight-400 text-black"
                                  href={`https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                                    cart?.client?.telephone
                                  )}`}
                                >
                                  {cart?.client?.telephone}
                                </a>
                              </span>
                            </CardBody>
                          </Card>
                        )}
                        {cart?.delivery_address?.street_name && (
                          <Card className="card new-layout mb-4 w-100">
                            <CardBody className="card-body new-layout px-3 py-3">
                              <span className="font-size-12 font-weight-400 text-gray d-block">
                                Endereço de entrega
                              </span>
                              <span className="font-size-14 font-weight-400 text-black">
                                {cart?.delivery_address?.street_name},{' '}
                                {cart?.delivery_address?.number} <br />
                                {cart?.delivery_address?.neighborhood} -{' '}
                                {cart?.delivery_address?.city} -{' '}
                                {cart?.delivery_address?.state}
                              </span>
                            </CardBody>
                          </Card>
                        )}
                      </>
                    ) : (
                      <>
                        {cart?.store?.address?.street_name && (
                          <Card className="card new-layout mb-4 w-100">
                            <CardBody className="card-body new-layout px-3 py-3">
                              <span className="font-size-12 font-weight-400 text-gray d-block">
                                Endereço de retirada
                              </span>
                              <a
                                href={`https://www.google.com/maps/dir/?api=1&destination=${cart?.store?.address?.lat},${cart?.store?.address?.long}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <span className="font-size-14 font-weight-400 text-black">
                                  {cart?.store?.address?.street_name} ,{' '}
                                  {cart?.store?.address?.number} <br />
                                  {cart?.store?.address?.neighborhood} -{' '}
                                  {cart?.store?.address?.city} -{' '}
                                  {cart?.store?.address?.state}
                                </span>
                              </a>
                              {/* <Row className="py-2 align-items-center">
                              <Col
                                xs={12}
                                lg={3}
                                className="px-0 pl-lg-2 mt-2 mt-lg-0 justify-content-center align-content-center d-flex"
                              >
                                <img src={storeIcon} alt="store" height={32} />
                              </Col>

                            </Row> */}
                            </CardBody>
                          </Card>
                        )}

                        {cart?.client && (
                          <Card className="card new-layout mb-4 w-100">
                            <CardBody className="card-body new-layout px-3 py-3">
                              <span className="font-size-12 font-weight-400 text-gray d-block">
                                Cliente
                              </span>
                              <span className="font-size-14 font-weight-400 text-black">
                                {cart?.client?.name} <br />
                                <a
                                  className="font-size-14 font-weight-400 text-black"
                                  href={`https://api.whatsapp.com/send?phone=${formatWhatsappPhoneNumber(
                                    cart?.client?.telephone
                                  )}`}
                                >
                                  {cart?.client?.telephone}
                                </a>
                              </span>
                            </CardBody>
                          </Card>
                        )}
                      </>
                    )}

                    {/* <Row className="w-100 mx-0 px-3 pb-2 mb-md-4 my-3 d-block">
                      <span className="font-size-14 font-weight-400 text-black d-block mx-0">
                        Valor
                      </span>
                      <hr className="text-black my-1" />
                      <div className="d-flex justify-content-between py-1">
                        <span className="font-size-12 font-weight-400 text-gray-600">
                          Subtotal
                        </span>
                        <span className="font-size-12 font-weight-400 text-gray-600">
                          {formatPrice(cart?.amount)}
                        </span>
                      </div>
                      <hr className="text-black my-1" />
                      <div className="d-flex justify-content-between py-1">
                        <span className="font-size-12 font-weight-400 text-gray-600">
                          Frete
                        </span>
                        <span className="font-size-12 font-weight-700 text-black">
                          {cart?.freight !== 0 ? (
                            formatPrice(cart?.freight)
                          ) : (
                            <>
                              <span className="text-green">Grátis</span>
                              {!cart?.is_for_delivery && (
                                <span>(retirada em loja)</span>
                              )}
                            </>
                          )}
                        </span>
                      </div>
                      <hr className="text-black my-1" />
                      <div className="d-flex justify-content-between py-1">
                        <span className="font-size-14 font-weight-700 text-black">
                          Total
                        </span>
                        <span className="font-size-14 font-weight-700 text-black">
                          {formatPrice(
                            Number(cart?.amount)
                          )}
                        </span>
                      </div>
                    </Row> */}
                  </Row>
                </CardBody>
              </Card>
            </Col>

            {Date.parse(cart?.url_shortener?.date_expiration) < Date.now() && (
              <Col lg={8} className="p-5 mt-2 mt-lg-0">
                <p className="font-size-24 font-weight-bold">
                  Este link expirou, entre em contato com o vendedor para
                  receber um novo link.
                </p>
                <Row
                  className="w-100 mx-0 d-flex flex-row justify-content-center align-items-center"
                  style={{ marginTop: '48px' }}
                >
                  <img
                    src={SaleExpirated}
                    alt="Icone que mostra que o link está expirado, com um tracejado amarelo ao redor e no meio um alerta"
                  />
                </Row>
              </Col>
            )}
            {cart?.status === 6 && (
              <Col lg={8} className="p-5 mt-2 mt-lg-0">
                <p className="font-size-24 font-weight-bold">
                  Seu pedido foi cancelado, para dúvidas e esclarecimentos entre
                  em contato com o seu vendedor.
                </p>
                <Row
                  className="w-100 mx-0 d-flex flex-row justify-content-center align-items-center"
                  style={{ marginTop: '48px' }}
                >
                  <img
                    src={SaleCancelled}
                    alt="Icone que mostra que o seu pedido foi cancelado, com um tracejado vermelho ao redor e no meio um simbolo de X"
                  />
                </Row>
              </Col>
            )}
            {cart?.status === 4 && (
              <Col lg={8} className="p-5 mt-2 mt-lg-0">
                <p className="font-size-24 font-weight-bold">
                  {cart?.delivery_method
                    ? 'Seu pedido já foi entregue ou retirado em loja, espero que tenha gostado e volte sempre!'
                    : 'Pagamento confirmado, já pode retirar seu pedido em loja.'}
                </p>
                <Row
                  className="w-100 mx-0 d-flex flex-row justify-content-center align-items-center"
                  style={{ marginTop: '48px' }}
                >
                  <img
                    src={BoxIconSVG}
                    alt="Icone que mostra que o seu pedido foi pago, com um tracejado verde ao redor e no meio um simbolo de ok"
                  />
                </Row>
              </Col>
            )}
            {cart?.status !== 6 && cart?.status !== 4 && (
              <Col lg={8} className="p-5 mt-2 mt-lg-0">
                <p className="font-size-24 font-weight-bold text-center">
                  {finishedSuccessfully
                    ? getClientMessage()
                    : 'Ocorreu um erro com o seu pedido'}
                </p>
                <Row
                  className="w-100 mx-0 d-flex flex-row justify-content-center align-items-center"
                  style={{ marginTop: '48px' }}
                >
                  <img
                    src={finishedSuccessfully ? getClientIcon() : SaleError}
                    alt="venda-finalizada"
                  />
                </Row>
                {/* (!finishedSuccessfully ||
                    cart?.delivery?.status_text === 'Entrega cancelada') && (
                    <Row className="w-100 mx-0 mt-5 pt-5 pl-5 pr-5">
                      <Button
                        className="new-layout-btn main w-100 review-btn"
                        style={{
                          backgroundColor: cart?.store?.customize?.theme_color,
                          border: 0,
                        }}
                        size="lg"
                        onClick={() => {
                          if (!cart.is_payment_checkout) {
                            dispatch(setActiveTab(3));
                          } else {
                            dispatch(setActiveTab(1));
                          }
                        }}
                      >
                        Revisar meu Pedido
                      </Button>
                    </Row>
                  ) */}
              </Col>
            )}
          </CardBody>
        </Card>
      </Container>
    </Row>
  );
}
