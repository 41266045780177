import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Form } from '@unform/web';

import InputSelect from '~/components/Inputs/InputSelect';

import api from '~/services/api';

import useRoles from '~/hooks/useRoles';
import { updateUserRequest } from '~/store/modules/user/actions';

const StorePicker = () => {
  const formRef = useRef(null);
  const dispatch = useDispatch();

  const [userParam, setUserParam] = useState();
  const { user_id } = useParams();

  const [selectedStore, setSelectedStore] = useState();

  const roles = useSelector(({ user }) =>
    userParam ? userParam?.roles : user.profile.roles
  );

  const isAdmin = useMemo(
    () => roles.find(role2 => role2.slug === 'administrator'),
    [roles]
  );

  const isSupport = useMemo(
    () => roles.find(role2 => role2.slug === 'support'),
    [roles]
  );

  useEffect(() => {
    const loadUser = async () => {
      try {
        const { data } = await api.get(
          `/${
            isAdmin || isSupport ? 'administrator' : 'store-manager'
          }/users/${user_id}`
        );

        setUserParam(data.user);
      } catch (err) {
        console.log(err);
      }
    };

    if (userParam) {
      loadUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_id]);

  const userSelected = useSelector(({ user }) =>
    userParam ? userParam?.id : user?.profile?.id
  );
  const userStore = useSelector(({ user }) =>
    userParam ? userParam?.stock : user?.profile?.stock
  );
  const userStores = useSelector(({ user }) =>
    userParam
      ? userParam?.managed_stores.map(s => ({
          value: s.id,
          label: s.fantasy_name,
        }))
      : user?.profile?.managed_stores.map(s => ({
          value: s.id,
          label: s.fantasy_name,
        }))
  );

  const { isStoreManager } = useRoles();

  const customStyles = {
    control: (base, state) => ({
      ...base,

      background: ' #2544b1',
      color: 'white',
      // borderRadius: '25px',
      borderColor: 'input-border-color',
      boxShadow: state.isFocused ? null : null,

      '&:hover': {
        // borderColor: 'primary',
        // backgroundColor: 'red',
      },
    }),
    singleValue: base => ({
      ...base,
      // color: 'red',
      // opacity: 0.65,
    }),
    input: base => ({
      ...base,
      // color: 'primary',
      // opacity: 0.65,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    }),
    menu: base => ({
      ...base,
      borderRadius: '4px',
      zIndex: '21474836',
      marginTop: '5px',
      width: `100%`,
    }),
    menuList: base => ({
      ...base,
      padding: 0,
      zIndex: '21474836',
      borderRadius: '4px',
      // width: `100%`,
      fontSize: '13px',
      fontWeight: '400',
      color: '#495057',
      // position: 'fixed',
      boxShadow: '0rem 0.175rem 0.25rem 0.1rem rgba(0, 0, 0, 0.15)',
    }),
    option: (base, state) => ({
      ...base,
      borderBottom: '1px solid lightgray',
      zIndex: '21474836',
      background: state.isSelected ? '#2544b1' : 'white',
      '&:hover': {
        backgroundColor: !state.isSelected ? '#f6f6f6' : '#2544b1',
      },
    }),
  };

  useEffect(() => {
    setSelectedStore({
      value: userStore?.id || '',
      label: userStore?.fantasy_name || '',
    });
  }, [userStore]);

  const setStoreSelectable = async option => {
    const store = userStores.find(p => p.value === option.value);

    if (!store) return;

    const selectStore = {
      value: store.value,
      label: store.label,
    };

    formRef.current.setFieldValue('store_id', selectStore.value);

    if (isStoreManager) {
      await api.patch(`store-manager/users/${userSelected}/stock/set`, {
        id: store.value,
      });
    } else if (isAdmin || userParam) {
      await api.patch(`administrator/users/${userSelected}/stock/set`, {
        id: store.value,
      });
    } else {
      await api.patch(`stock/set`, {
        id: store.value,
      });
    }

    setSelectedStore(selectStore);

    dispatch(updateUserRequest());
  };

  const handleValidSubmit = async () => {
    await api.patch(`stock/set`, {
      id: selectedStore.value,
    });
  };

  return (
    <Form
      onSubmit={handleValidSubmit}
      ref={formRef}
      initialData={{
        userStore,
      }}
      noValidate
      // style={{
      //   maxWidth: 350,
      // }}
      className="store-picker-form w-100"
    >
      <InputSelect
        options={userStores}
        name="store_id"
        styles={customStyles}
        onChange={setStoreSelectable}
        isSearchable
        // menuPortalTarget={document.body}
        menuShouldBlockScroll
        placeholder="Selecione a loja"
        type="text"
        value={selectedStore}
        loadingMessage={() => 'Buscando lojas...'}
        onBlur={() => {}}
        // menuIsOpen
      />
    </Form>
  );
};

export default StorePicker;
