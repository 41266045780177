/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Card,
  CardBody,
  Button,
  Label,
} from 'reactstrap';
import { toast } from 'react-toastify';
import Loading from '~/components/Loading';
import { saveUserData } from '~/store/modules/user/actions';

import Input from '~/components/Inputs/Input';
import api from '~/services/api';
import history from '~/services/history';

import { logoutUser } from '~/store/modules/auth/login/actions';
// import Dropzone from '~/components/Dropzone';

import { defineAlignment } from '~/util/newLayoutHelpers';
// import PageHeader from '~/components/Common/PageHeader';
import UserHeaderInfo from '~/components/Common/UserHeaderInfo';
import { LG } from '~/util/breakpointWidths';
import { maskBuilder } from '~/util/maskTelephone';
import InputMask from '~/components/Inputs/InputMask';
import StorePicker from '~/components/HeaderComponents/StorePicker';
import useRoles from '~/hooks/useRoles';
import PageHeader from '~/components/Common/PageHeader';

const MyProfile = () => {
  const staticUser = useSelector(({ user }) => user.profile);
  const [user, setUser] = useState();

  const dispatch = useDispatch();
  const UserLogged = useSelector(({ user: User }) => User.profile);
  const windowSize = useSelector(({ layout }) => layout.windowSize);

  const [loading, setLoading] = useState(true);
  const [telephoneV, setTelephone] = useState('');

  const {
    isAdmin,
    isStoreManager,
    isSalesman,
    isStockist,
    isConsultant,
    isFinancier,
    isCrossManager,
  } = useRoles();

  const notMaster = useMemo(
    () =>
      isStoreManager ||
      isStockist ||
      isSalesman ||
      isConsultant ||
      isAdmin ||
      isCrossManager ||
      isFinancier,
    [
      isStoreManager,
      isStockist,
      isSalesman,
      isConsultant,
      isAdmin,
      isCrossManager,
      isFinancier,
    ]
  );

  // const [profilePicture, setProfilePicture] = useState();

  const { user_id } = useParams();

  const formRef = useRef(null);

  useEffect(() => {
    document.title = 'Meu Perfil | Viddy';

    const loadInfo = async () => {
      try {
        const { data } = await api.get(`/auth/me`);
        setUser(data.user);
        setTelephone(data.user.telephone);

        setLoading(false);
      } catch (err) {
        history.goBack();
        if (err.response && err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error('Usuário não encontrada.');
        }
      }
    };

    loadInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmitHandler = async data => {
    try {
      formRef.current.setErrors({});

      const maskTelephoneLength = maskBuilder(telephoneV).length - 1;

      const noStartingWhitespace = value => {
        if (value && value.startsWith(' ')) {
          return false;
        }
        return true;
      };

      const onlyLowerCase = value => {
        if (value && value !== value.toLowerCase()) {
          return false;
        }
        return true;
      };

      const schema = Yup.object().shape({
        name: Yup.string()
          .required('Digite o nome do usuário')
          .test(
            'noStartingWhitespace',
            'O nome não pode começar com um espaço!',
            noStartingWhitespace
          ),
        email: Yup.string()
          .email('Digite um e-mail válido')
          .required('Digite o e-mail do usuário')
          .test(
            'onlyLowerCase',
            'O e-mail deve conter apenas letras minúsculas!',
            onlyLowerCase
          ),
        password: data.password
          ? Yup.string().min(6, 'Digite no mínimo 6 caracteres para senha')
          : Yup.string().notRequired(),
        password_confirmation: data.password
          ? Yup.string()
              .min(
                6,
                'Digite no mínimo 6 caracteres para a confirmação da senha'
              )
              .oneOf([Yup.ref('password'), null], 'As senhas não são iguais!')
              .required('A confirmação de senha é obrigatória')
          : Yup.string().notRequired(),
        telephone: data.telephone
          ? Yup.string()
              .min(
                maskTelephoneLength,
                'Insira todos os dígitos do número do telefone'
              )
              .required()
          : Yup.string().notRequired(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      data = { ...data };

      await api.patch(`/auth/me`, data);

      // if (profilePicture) {
      //   const formData = new FormData();

      //   formData.append('profile_picture', profilePicture);

      //   await api.post(`auth/me/set-profile-picture`, formData);
      // }

      if (UserLogged.id === user_id) {
        const {
          data: { user: userData },
        } = await api.get('auth/me');

        dispatch(saveUserData(userData));
      }

      history.push('/');
      toast.success('Dados editados com sucesso!');
      window.location.reload();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(e => {
          validationErrors[e.path] = e.message;
        });
        formRef.current.setErrors(validationErrors);
      }

      if (err.response.data.password) {
        toast.error(err.response.data.password[0]);
      }

      if (err && err.response?.status === 403) {
        toast.success('Dados alterados com sucesso. Faça o login novamente!');

        dispatch(logoutUser());
      }
    }
  };

  return (
    <div className="page-content">
      <Container className="container new-layout">
        {loading ? (
          <Loading />
        ) : (
          <>
            <PageHeader
              backTo="/"
              responsivePosition="start"
              desktopPosition="center"
            />
            <Form
              ref={formRef}
              onSubmit={onSubmitHandler}
              initialData={{
                name: user?.name,
                email: user?.email,
                identification_code: user?.identification_code,
                telephone: user?.telephone,
              }}
            >
              <Card className="card new-layout">
                <CardBody className="card-body new-layout">
                  <UserHeaderInfo user={staticUser} />
                  {notMaster && (
                    <>
                      <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 mb-3">
                        Minha Loja
                      </Row>
                      <Col lg={6} className="px-0 pr-lg-4 mb-3">
                        <Row className="w-100 mx-0">
                          <Col className="px-0">
                            <StorePicker />
                          </Col>
                        </Row>
                      </Col>
                    </>
                  )}
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 mb-3">
                    Dados pessoais
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-4">
                      <Row className="w-100 mx-0">
                        <Col className="px-0">
                          <FormGroup>
                            <Label>Nome</Label>
                            <Input
                              name="name"
                              type="text"
                              placeholder="Nome do usuário"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} className="px-0 pr-lg-4">
                      <Row className="w-100 mx-0">
                        <Col className="px-0">
                          <FormGroup>
                            <Label>E-mail</Label>
                            <Input
                              name="email"
                              type="text"
                              placeholder="E-mail"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0">
                    {/* <Col lg={6} className="px-0 pr-lg-4">
                      <Row className="w-100 mx-0">
                        <Col className="px-0">
                          <FormGroup>
                            <Label>Código de Identificação</Label>
                            <Input
                              name="identification_code"
                              type="text"
                              placeholder="Código de Identificação"
                              classRest="input-disabled"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                  </Col> */}
                    <Col lg={6} className="px-0 pr-lg-4">
                      <Row className="w-100 mx-0">
                        <Col className="px-0">
                          <FormGroup>
                            <Label>Telefone:</Label>
                            <InputMask
                              mask={maskBuilder(telephoneV)}
                              maskChar={null}
                              name="telephone"
                              defaultValue={telephoneV}
                              placeholder="Telefone"
                              type="text"
                              onChange={e => setTelephone(e.target.value)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="w-100 mx-0 font-size-20 font-weight-700 text-gray-700 mb-3">
                    Alteração de senha
                  </Row>
                  <Row className="w-100 mx-0">
                    <Col lg={6} className="px-0 pr-lg-4">
                      <Row className="w-100 mx-0">
                        <Col className="px-0">
                          <FormGroup>
                            <Label>Nova senha</Label>
                            <Input
                              name="password"
                              label="Senha"
                              type="password"
                              errorMessage="Digite a nova senha"
                              placeholder="Digite a nova senha"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col lg={6} className="px-0 pr-lg-4">
                      <Row className="w-100 mx-0">
                        <Col className="px-0">
                          <FormGroup>
                            <Label>Confirmação de senha</Label>
                            <Input
                              name="password_confirmation"
                              label="Confirmação de senha"
                              type="password"
                              errorMessage="Digite a nova senha novamente"
                              placeholder="Digite a nova senha novamente"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Row className="w-100 mx-0 my-4 ">
                <Col
                  align={defineAlignment(windowSize, LG, 'center', 'end')}
                  className="px-0"
                >
                  <Button
                    color="primary"
                    className="new-layout-btn main px-4 my-4"
                    size="lg"
                  >
                    Salvar Alterações
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Container>
    </div>
  );
};

export default MyProfile;
